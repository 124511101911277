import React from 'react'
import Layout from '../components/ui-components/containers/layout'
import TextContainer from '../components/ui-components/containers/text-container'

const Imprint = () => {
  const address = 'Universitätsstr. 35\n44789 Bochum'
  const phone = '+4915789489705'
  const mail = 'mail@nilsschlueter.de'
  const style = {
    'white-space': 'pre-line',
  }

  return (
    <Layout>
      <TextContainer>
        <div className="mt-12">
          <h1 className="text-2xl mb-2 font-bold">Impressum</h1>
          <h2 className="text-xl"> Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
          <p>
            Anschrift:
            <p className="mt-4"> Shine On</p>
            <p className="mt-4"> Manuel Schmidt</p>
            <p className="mt-4"> Altenhöfener Str. 133</p>
            <p className="mt-4"> 44623 Herne</p>
            <p className="mt-4"> Tel.: 01634782100</p>
            <p className="mt-4"> E-Mail: info@shineonofficial.de</p>
          </p>
        </div>
      </TextContainer>
    </Layout>
  )
}

export default Imprint
